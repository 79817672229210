import request from '@/utils/request.js';
/**
 * 门禁识别-获取数据统计结果列表
 * @returns {Promise}
 */
export function getDcIdentifyList(data) {
  return request({
    url: '/traffic-construction/dcIdentify/list',
    method: 'post',
    data
  });
}

/**
 * 门禁识别-获取在场人员名单
 * @returns {Promise}
 */
 export function getPresentPersonnelList(params) {
  return request({
    url: '/traffic-construction/dcIdentify/presentPersonnelList',
    method: 'get',
    params
  });
}


/**
 * 门禁识别-获取在场人员名单
 * @returns {Promise}
 */
 export function getAdmissionPersonnelList(params) {
  return request({
    url: '/traffic-construction/dcIdentify/admissionPersonnelList',
    method: 'get',
    params
  });
}
