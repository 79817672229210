<template>
  <div class="operate-box">
    <div class="header-operate">
      <span
        v-for="(item, index) in buttonGroupList"
        :key="item.property"
        :class="{ 'high-light': whichIndex == index }"
        @click="switchDisplay(index, item.property)"
        >{{ item.text }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'SwicthButton',
  data() {
    return {
      buttonGroupList: [
        {
          text: '在场人员名单',
          property: 'personnelPresent'
        },
        {
          text: '入场人员名单',
          property: 'admissionPersonnel'
        }
      ],
      whichIndex: 0
    };
  },
  methods: {
    switchDisplay(index, property) {
      this.whichIndex = index;
      this.$emit('switch-display', property);
    }
  }
};
</script>
<style scoped lang="less">
.operate-box {
  .header-operate {
    right: 0;
    top: -56px;
    span {
      display: inline-block;
      width: 108px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #c0c4ce;
      color: #616266;
    }
    .high-light {
      background: #005aab;
      border: 1px solid #005aab;
      color: #fff;
    }
  }
}
</style>
