<template>
  <div>
    <!-- 列表搜索 -->
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="所属机构">
          <el-select v-model="queryForm.orgIdList" placeholder="请选择所属机构" multiple collapse-tags>
            <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询日期">
          <el-date-picker
            style="width:250px"
            value-format="yyyy-MM-dd"
            v-model="queryForm.opTime"
            type="date"
            placeholder="请选择查询日期"
            :picker-options="pickerOptions"
            @change="handlerOpTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handelSearch">查询</el-button>
          <el-button @click="handelReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <div>
      <el-table :data="tableData" stripe style="width: 100%" v-loading="tabelLoading" ref="multipleTabel">
        <el-table-column label="序号" width="55" fixed align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="orgName" label="所属机构" fixed align="center" sortable> </el-table-column>
        <el-table-column prop="dcNum" label="门禁数量" align="center" sortable> </el-table-column>
        <el-table-column prop="attendanceNum" :label="`${persentLabel}入场人次`" align="center" sortable>
        </el-table-column>
        <el-table-column prop="currentAttendanceNum" :label="`${persentLabel}在场人数`" align="center" sortable>
          <template slot-scope="{ row }">
            <span>{{ row.currentAttendanceNum === '' ? '-' : row.currentAttendanceNum }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="120" align="center">
          <template slot-scope="{ row }">
            <span class="action-btn" @click="handelviewDetail(row.orgId)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="queryForm.current"
        :page-size="queryForm.size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- dialog -->
    <el-dialog
      width="900px"
      title="查看详情"
      :visible.sync="personTabelvisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-if="personTabelvisible"
    >
      <swicth-button @switch-display="switchDisplay" style="margin-bottom:16px"></swicth-button>
      <transition mode="out-in" name="component-transition">
        <!-- 切换列表 -->
        <component :is="isPersonShow" :tabel-afferent-params="tabelAfferentParams"></component>
      </transition>
    </el-dialog>
  </div>
</template>

<script>
import { getDoorControlOrgList } from 'api/common';
import { getDcIdentifyList } from 'api/general-user/visualization/access-identity';
import swicthButton from './swicthButton';
import personnelPresent from './personnelPresent';
import admissionPersonnel from './admissionPersonnel';
export default {
  components: {
    swicthButton,
    admissionPersonnel,
    personnelPresent
  },
  data() {
    return {
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      tabelLoading: false,
      queryForm: {
        current: 1,
        size: 10,
        opTime: '',
        orgId: ''
      },
      tableData: [],
      orgList: [],
      total: 0,
      //传给子组件的数据
      tabelAfferentParams: {
        orgId: '',
        opTime: ''
      },
      //dialog数据
      personTabelvisible: false,
      personInforList: [],
      isPersonShow: 'personnelPresent',
      //默认显示在场
      persentLabel: '当前'
    };
  },
  methods: {
    switchDisplay(componentName) {
      this.isPersonShow = componentName;
    },
    handlerOpTime(time) {
      this.persentLabel = time !== this.getDateFunc() ? '历史' : '当前';
      this.tabelAfferentParams.opTime = time;
    },
    //js获取年月日
    getDateFunc() {
      var myDate = new Date();
      const year = myDate.getFullYear();
      const month = myDate.getMonth() + 1;
      const day = myDate.getDate();
      return year + '-' + month + '-' + day;
    },
    handelSearch() {
      this.queryForm.current = 1;
      this.queryForm.size = 10;
      this.getDcIdentifyList();
    },
    handelReset() {
      //重置
      this.queryForm = {
        current: 1,
        size: 10,
        opTime: '',
        orgIdList: ''
      };
      this.$refs.multipleTabel.clearSort();
      this.tabelAfferentParams = {
        orgId: '',
        opTime: ''
      };
      this.persentLabel = '当前';
      this.getDcIdentifyList();
    },
    handleSizeChange(size) {
      this.queryForm.current = 1;
      this.queryForm.size = size;
      this.getDcIdentifyList();
    },
    handleCurrentChange(current) {
      this.queryForm.current = current;
      this.getDcIdentifyList();
    },
    //查看详情
    handelviewDetail(orgId) {
      this.isPersonShow = 'personnelPresent';
      this.tabelAfferentParams.orgId = orgId;
      this.personTabelvisible = true;
    },
    //获取机构列表
    getDoorControlOrgList() {
      getDoorControlOrgList().then(res => {
        if (res.code === 200) {
          this.orgList = res.data;
        }
      });
    },
    //获取列表
    getDcIdentifyList() {
      this.tabelLoading = true;
      getDcIdentifyList(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tabelLoading = false;
          const { records, total } = res.data;
          this.total = total;
          this.tableData = records;
        }
      });
    }
  },
  created() {
    this.getDoorControlOrgList();
    this.getDcIdentifyList();
  }
};
</script>
