<template>
  <div>
    <el-table
      :data="presentPersonInforList"
      style="width: 100%"
      :cell-style="{ background: '#fff' }"
      size="medium"
      border
      v-loading="dialogLoading"
    >
      <el-table-column label="序号" width="55" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="人员姓名" align="center"> </el-table-column>
      <el-table-column prop="enterTime" label="入场时间" align="center"> </el-table-column>
      <el-table-column prop="enterPicUrl" label="抓拍照片" align="center">
        <template slot-scope="{ row }">
          <img
            :src="row.enterPicUrl"
            alt="抓拍图片"
            @click="handlePictureCardPreview(row.enterPicUrl)"
            class="picture-image"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="padding-bottom:16px"
      :total="total"
      :page-size="4"
      :current-page="tabelParams.current"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next"
    ></el-pagination>
    <!-- 预览图片 -->
    <el-dialog :visible.sync="imageVisible" append-to-body>
      <img :src="dialogImageUrl" alt="预览抓拍照片" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import { getPresentPersonnelList } from 'api/general-user/visualization/access-identity';
export default {
  name: 'PersonnelPresent',
  data() {
    return {
      dialogLoading: false,
      presentPersonInforList: [],
      tabelParams: {
        current: 1,
        size: 4
      },
      total: 0,
      imageVisible: false,
      dialogImageUrl: ''
    };
  },
  props: {
    tabelAfferentParams: {
      type: Object,
      required: true,
      default: () => {
        {
        }
      }
    }
  },
  methods: {
    //预览图片
    handlePictureCardPreview(imageUrl) {
      this.dialogImageUrl = imageUrl;
      this.imageVisible = true;
    },
    handleCurrentChange(current) {
      this.tabelParams.current = current;
      this.getPresentPersonnelList();
    },
    //查看在场人员名单api
    getPresentPersonnelList() {
      this.dialogLoading = true;
      getPresentPersonnelList(this.tabelParams).then(res => {
        if (res.code === 200) {
          this.dialogLoading = false;
          const { total, records } = res.data;
          this.total = total;
          this.presentPersonInforList = records;
        }
      });
    }
  },
  watch: {
    tabelAfferentParams: {
      handler: function(newVal) {
        this.tabelParams = Object.assign(this.tabelParams, newVal);
        this.getPresentPersonnelList();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.picture-image {
  width: 80px;
  height: 80px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}
</style>
